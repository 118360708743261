import VueRouter                                from 'vue-router';

import beforeNavigationGuard                    from './beforeNavigationGuard.js';

const AppHeader                                 = () => import(/* webpackChunkName: "AppShell" */'../components/molecules/AppHeader/m-AppHeader.vue');
const AppFooter                                 = () => import(/* webpackChunkName: "AppShell" */'../components/molecules/AppFooter/m-AppFooter.vue');
const Emboarding                                = () => import('../features/Emboarding/components/p-Emboarding.vue');
const EmboardingWelcome                         = () => import('../features/Emboarding/components/p-EmboardingWelcome.vue');
const ArtistEmboardingTelephone                 = () => import('../features/Emboarding/components/o-ArtistEmboardingTelephone.vue');
const ArtistEmboardingActivity                  = () => import('../features/Emboarding/components/o-ArtistEmboardingActivity.vue');
const ArtistDashboard                           = () => import(/* webpackChunkName: "ArtistDashboard" */'../features/ArtistDashboard/components/p-ArtistDashboard.vue');
const ArtistDashboardSettings                   = () => import(/* webpackChunkName: "ArtistDashboard" */'../features/ArtistDashboard/components/o-ArtistDashboardSettings.vue');
const ArtistDashboardBookings                   = () => import('../features/ArtistDashboard/components/o-ArtistDashboardBookings.vue');
const ArtistDashboardBooking                    = () => import('../features/ArtistDashboard/components/o-ArtistDashboardBooking.vue');
const ArtistPayments                            = () => import('../features/ArtistPayments/components/p-ArtistPayments.vue');
const ArtistProfile                             = () => import('../features/ArtistProfile/components/p-ArtistProfile.vue');
const ArtistProfilePublic                       = () => import('../features/ArtistProfile/components/p-ArtistPublicProfile.vue');
const ArtistEditAgenda                          = () => import('../features/ArtistEditAgenda/components/p-ArtistEditAgenda.vue');
const ArtistSignin                              = () => import('../features/Signin/components/p-ArtistSignin.vue');
const Chat                                      = () => import('../features/Chat/components/p-Chat.vue');
const ChatConversationsList                     = () => import('../features/Chat/components/o-ChatConversationsList.vue');
const ChatConversationDiscussion                = () => import('../features/Chat/components/o-ChatConversationDiscussion.vue');
const Signup                                    = () => import('../features/Signup/components/p-Signup.vue');
const ClientSignin                              = () => import('../features/Signin/components/p-ClientSignin.vue');
const PasswordRecovery                          = () => import('../features/PasswordRecovery/components/p-PasswordRecovery.vue');
const PasswordReset                             = () => import('../features/PasswordReset/components/p-PasswordReset.vue');
const MailValidation                            = () => import('../features/Signup/components/p-MailValidation.vue');
const MailValidated                             = () => import('../features/Signup/components/p-MailValidated.vue');
const EditIdentityForm                          = () => import('../features/EditArtist/components/o-EditIdentityForm.vue');
const ClientDashboard                           = () => import(/* webpackChunkName: "ClientDashboard" */'../features/ClientDashboard/components/p-ClientDashboard.vue');
const ClientDashboardBottomMenu                 = () => import(/* webpackChunkName: "ClientDashboard" */'../features/ClientDashboard/components/o-ClientDashboardBottomMenu.vue');
const ClientSearchArtistList                    = () => import(/* webpackChunkName: "ClientDashboard" */'../features/ClientDashboard/components/o-ClientSearchArtistList.vue');
const ClientSearchSecondaryFilters              = () => import(/* webpackChunkName: "ClientDashboard" */'../features/ClientDashboard/components/o-ClientSearchSecondaryFilters.vue');
const ClientBooking                             = () => import('../features/ClientBooking/components/o-ClientBooking.vue');
const BookingCancel                             = () => import('../features/ClientBooking/components/p-BookingCancel.vue');
const Landing                                   = () => import('../features/Landing/components/p-Landing.vue');
const LandingAppHeader                          = () => import('../features/Landing/components/o-LandingAppHeader.vue');
const ServiceFAQ                                = () => import('../features/ServiceFAQ/components/p-ServiceFAQ.vue');
const ServiceTerms                              = () => import('../features/ServiceTerms/components/p-ServiceTerms.vue');
const NotFound                                  = () => import('../features/NotFound/components/p-NotFound.vue');


const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'LandingPage',
      components: {
        default: Landing,
        header: LandingAppHeader,
        footer: AppFooter,
      },
    },
    {
      path: '/artist/signin',
      name: 'ArtistSignin',
      components: {
        default: ArtistSignin
      },
      meta: {
        isNotAllowedWhenSignedIn: true,
      },
    },
    {
      path: '/artist/signup',
      name: 'ArtistSignup',
      components: {
        default: Signup
      },
      props: {
        default: { isArtist: true, },
      },
      meta: {
        isNotAllowedWhenSignedIn: true,
      },
    },
    {
      path: '/password-reset&token=:token',
      name: 'PasswordReset',
      components: {
        default: PasswordReset,
      },
      props: {
        default: true,
      },
      meta: {
        isNotAllowedWhenSignedIn: true,
      },
    },
    {
      path: '/artist/recovery',
      name: 'ArtistPasswordRecovery',
      components: {
        default: PasswordRecovery,
      },
      props: {
        default: { isArtist: true }
      },
      meta: {
        isNotAllowedWhenSignedIn: true,
      },
    },
    {
      path: '/artist/signup/mail-validation',
      name: 'ArtistMailValidation',
      components: {
        default: MailValidation,
      },
      meta: {
        isNotAllowedWhenSignedIn: true,
      },
    },
    {
      path: '/artist/signup/mail-validated&token=:token',
      name: 'ArtistMailValidated',
      components: {
        default: MailValidated,
      },
      props: {
        default: true,
      },
    },
    {
      path: '/artist/emboarding',
      component: Emboarding,
      props: { isArtist: true },
      meta: {
        isAuthenticationRequired: true,
        isArtistOnly: true,
        stepIndex: 0,
      },
      children: [
        {
          path: 'identity',
          name: 'ArtistEmboardingIdentity',
          component: EditIdentityForm,
          meta: {
            stepIndex: 0,
          },
        },
        {
          path: 'telephone',
          name: 'ArtistEmboardingTelephone',
          component: ArtistEmboardingTelephone,
          meta: {
            stepIndex: 1,
          },
        },
        {
          path: 'activity',
          name: 'ArtistEmboardingActivity',
          component: ArtistEmboardingActivity,
          meta: {
            stepIndex: 2,
          },
        },
      ],
    },
    {
      path: '/artist/welcome',
      name: 'ArtistEmboardingWelcome',
      component: EmboardingWelcome,
      props: {
        isArtist: true,
      },
      meta: {
        isAuthenticationRequired: true,
      },
    },
    {
      path: '/artist',
      components: {
        default: ArtistDashboard,
        header: AppHeader,
        footer: AppFooter,
      },
      meta: {
        isAuthenticationRequired: true,
        isArtistOnly: true,
        isEmboardingRequired: true,
      },
      children: [
        {
          path: '/',
          name: 'ArtistDashboard',
          component: ArtistDashboardSettings,
          props: true,
          meta: {
            breadcrumb: [
              { label: 'common.dashboard', link: { name: 'ArtistDashboard' }, },
              { label: 'settings.identity' },
            ],
          },
        },
        {
          path: 'bookings',
          name: 'ArtistDashboardBookings',
          component: ArtistDashboardBookings,
          props: () => ({ isArtist: true }),
          meta: {
            breadcrumb: [
              { label: 'common.myAccount', link: { name: 'ArtistDashboard' }, },
              { label: 'artist.dashboard.bookings.bookingsAndPayments', },
            ],
          },
        },
        {
          path: 'payments',
          name: 'ArtistPayments',
          component: ArtistPayments,
          props: true,
          meta: {
            breadcrumb: [
              { label: 'common.myAccount', link: { name: 'ArtistDashboard' }, },
              { label: 'common.paymentInformations' },
            ],
          },
        },
        {
          path: 'booking/:id',
          name: 'ArtistDashboardBooking',
          component: ArtistDashboardBooking,
          props: true,
          meta: {
            isGreyBackground: true,
            breadcrumb: [
              { label: 'common.myAccount', link: { name: 'ArtistDashboard' }, },
              { label: 'common.paymentInformations' },
            ],
          },
        },
        {
          path: 'faq',
          name: 'ServiceFAQ',
          components: {
            header: AppHeader,
            default: ServiceFAQ,
            footer: AppFooter,
          },
          meta: {
            breadcrumb: [
              { label: 'common.myAccount', link: { name: 'ArtistDashboard' }, },
              { label: 'faqArtist.title', },
            ],
          },
        },
      ],
    },
    {
      path: '/artist/profile',
      name: 'ArtistProfile',
      components: {
        default: ArtistProfile,
        header: AppHeader,
        footer: AppFooter,
      },
      meta: {
        isArtistOnly: true,
        isAuthenticationRequired: true,
        isGreyBackground: true,
        isEmboardingRequired: true,
      },
    },
    {
      path: '/artist/profile/public/:id',
      name: 'ArtistProfilePublicPreview',
      components: {
        default: ArtistProfilePublic,
        header: AppHeader,
        footer: AppFooter,
      },
      props: {
        default: route => ({
          id: route.params.id,
          isPreview: true,
        }),
      },
      meta: {
        isArtistOnly: true,
        isAuthenticationRequired: true,
        isEmboardingRequired: true,
      }
    },
    {
      path: '/artist/profile/:id',
      name: 'ArtistProfilePublic',
      components: {
        default: ArtistProfilePublic,
        header: AppHeader,
        footer: AppFooter,
      },
      props: {
        default: true,
      },
      meta: {
        isSearchBar: true,
        isEmboardingRequired: true,
      },
    },
    {
      path: '/artist/chat',
      components: {
        default: Chat,
        header: AppHeader,
        footer: AppFooter,
      },
      meta: {
        isArtistOnly: true,
        isAuthenticationRequired: true,
        isEmboardingRequired: true,
      },
      children: [
        {
          path: '/',
          name: 'Chat',
          components: {
            list: ChatConversationsList,
            messages: ChatConversationDiscussion,
          },
          children: [
            {
              path: ':id'
            }
          ],
        },
      ],
    },
    {
      path: '/artist/edit/agenda',
      name: 'ArtistEditAgenda',
      components: {
        default: ArtistEditAgenda,
      },
      meta: {
        isArtistOnly: true,
        isAuthenticationRequired: true,
        isEditAgenda: true,
        isEmboardingRequired: true,
      },
    },
    {
      path: '/client/signin',
      name: 'ClientSignin',
      components: {
        default: ClientSignin,
      },
      meta: {
        isNotAllowedWhenSignedIn: true,
      }
    },
    {
      path: '/client/signup',
      name: 'ClientSignup',
      components: {
        default: Signup,
      },
      meta: {
        isNotAllowedWhenSignedIn: true,
      },
    },
    {
      path: '/client/signup/mail-validation',
      name: 'ClientMailValidation',
      components: {
        default: MailValidation,
      },
      meta: {
        isNotAllowedWhenSignedIn: true,
      }
    },
    {
      path: '/client/signup/mail-validated&token=:token',
      name: 'ClientMailValidated',
      components: {
        default: MailValidated,
      },
      props: {
        default: props => ({ token: props.params.token, }),
      },
      meta: {
        isNotAllowedWhenSignedIn: true,
      }
    },
    {
      path: '/client/recovery',
      name: 'ClientPasswordRecovery',
      components: {
        default: PasswordRecovery,
      },
      props: {
        default: { isArtist: false },
      },
      meta: {
        isNotAllowedWhenSignedIn: true,
      },
    },
    {
      path: '/client/emboarding',
      component: Emboarding,
      meta: {
        isAuthenticationRequired: true,
        stepIndex: 0,
      },
      children: [
        {
          path: 'identity',
          name: 'ClientEmboardingIdentity',
          component: EditIdentityForm,
          meta: {
            stepIndex: 0,
          },
        },
        {
          path: 'telephone',
          name: 'ClientEmboardingTelephone',
          component: ArtistEmboardingTelephone,
          meta: {
            stepIndex: 1,
          },
        },
      ],
    },
    {
      path: '/client/welcome',
      name: 'ClientEmboardingWelcome',
      component: EmboardingWelcome,
      meta: {
        isAuthenticationRequired: true,
      },
    },
    {
      path: '/client',
      components: {
        header: AppHeader,
        default: ClientDashboard,
        footer: AppFooter,
      },
      meta: {
        isEmboardingRequired: true,
      },
      props: true,
      children: [
        {
          path: '/',
          name: 'ClientDashboard',
          components: {
            default: ClientSearchArtistList,
            drawer: ClientDashboardBottomMenu,
            filters: ClientSearchSecondaryFilters,
          },
          meta: {
            breadcrumb: [
              { label: 'common.home', link: { name: 'LandingPage' }, },
              { label: 'common.search', },
            ],
            isGreyBackground: true,
            isSearchBar: true,
            layout: 'search',
            deleteSearchFiltersIfGoingTo: ['LandingPage', 'ClientDashboardSettings', 'ClientBookings'],
          },
        },
        {
          path: 'account',
          name: 'ClientDashboardSettings',
          components: {
            header: AppHeader,
            default: ArtistDashboardSettings,
            footer: AppFooter,
          },
          meta: {
            isAuthenticationRequired: true,
            redirect: 'ClientSignin',
            isGreyBackground: true,
            breadcrumb: [
              { label: 'common.dashboard', link: { name: 'ClientDashboardSettings' }, },
              { label: 'settings.identity', },
            ],
          },
        },
        {
          path: 'book/:id',
          name: 'ClientBooking',
          components: {
            default: ClientBooking,
          },
          props: {
            default: true,
          },
          meta: {
            isAuthenticationRequired: true,
            redirect: 'ClientSignup',
            isGreyBackground: true,
            breadcrumb: [
              { label: 'common.home', link: { name: 'LandingPage' }, },
              { label: 'common.search', link: { name: 'ClientDashboard' }, },
              { label: (component) => component?.store?.state?.SearchArtist?.currentArtist?.ArtisteNicknameGenerated ?? '' },
            ],
          },
        },
        {
          path: 'bookings',
          name: 'ClientBookings',
          components: {
            default: ArtistDashboardBookings,
          },
          meta: {
            isAuthenticationRequired: true,
            redirect: 'ClientSignin',
            breadcrumb: [
              { label: 'common.myAccount', link: { name: 'ClientDashboardSettings' }, },
              { label: 'artist.dashboard.bookings.bookingsAndPayments', },
            ],
          },
        },
        {
          path: 'booking/:id',
          name: 'ClientBookingDetails',
          component: ArtistDashboardBooking,
          props: route => ({ id: route.params.id, }),
          meta: {
            isAuthenticationRequired: true,
            redirect: 'ClientSignin',
            isGreyBackground: true,
            breadcrumb: [
              { label: 'common.myAccount', link: { name: 'ArtistDashboard' }, },
              { label: 'artist.dashboard.bookings.bookingsAndPayments', link: { name: 'ClientBookings' }, },
              { label: 'common.booking', },
            ],
          },
        },
        {
          path: 'booking/cancel/:id',
          name: 'ClientBookingCancel',
          component: BookingCancel,
          props: route => ({ id: route.params.id, }),
          meta: {
            isAuthenticationRequired: true,
            redirect: 'ClientSignin',
            breadcrumb: [
              { label: 'common.myAccount', link: { name: 'ArtistDashboard' }, },
              { label: 'artist.dashboard.bookings.bookingsAndPayments', link: { name: 'ClientBookings' }, },
              { label: 'common.booking', link: (component) => ({ name: 'ClientBookingDetails', params: { id: component.$route.params.id } }) },
              { label: 'Annulation', },
            ],
          },
        },
      ],
    },
    {
      path: '/terms',
      name: 'ServiceTerms',
      components: {
        header: AppHeader,
        default: ServiceTerms,
        footer: AppFooter,
      },
    },
    {
      path: '*',
      name: 'NotFound',
      components: {
        header: false,
        default: NotFound,
      },
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    let timeout = null;

    if (to.hash) {
      timeout = setTimeout(() => {
        window.scrollTo({
          top: (document.querySelector(to.hash)?.offsetTop ?? 100) - 100,
          behavior: 'smooth',
        });
        clearTimeout(timeout);

        timeout = null;
      }, 250);
    }

    return savedPosition ?? { x: 0, y: 0 };
  }
});

router.beforeEach(beforeNavigationGuard);


export default router;
