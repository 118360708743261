import gql      from 'graphql-tag';


export const EDIT_CLIENT = gql`
  mutation updateClient($input: UpdateClientInput!) {
    updateClient(input: $input) {
      ... on Client {
        ID
        Token
        Email
        Bookings {
          ID
          EventType
          EventDate
          PriceForClient
          PriceForArtist
          CanceledBeforeDueDate
          Line1
          Line2
          PostalCode
          City
          Country
          StartTime
          EndTime
          Status
          IsMaterialNeeded
          IsCanceledByClient
          NumberOfPeople
          ClientEventType
          Client {
            Email
            Firstname
            Lastname
            PhoneNumber
          }
          Artist {
            Email
            Firstname
            Lastname
            PhoneNumber
          }
        }
        Firstname
        Lastname
        Birthday
        Gender
        PhoneNumber
        Line1
        Line2
        PostalCode
        City
        Country
      }

      ... on EmailNotValidate {
        email
        message
        status
      }

      ... on Unauthorized {
        message
        status
      }

      ... on TokenError {
        message
        status
        requestID
      }
    }
  }
`;